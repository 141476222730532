/**
 * techtechniques WordPress Theme
 * -----------------------------------------------------------------------------
 *
 * NOTE: You might need to use `require()` instead of `import`.
 */

// Imports
// const { Is } = require('./utilities/index');

// Polyfills
// focus within polyfill
var focusWithin = require('ally.js/style/focus-within');
focusWithin();

// get the type of user input
require('what-input');

// object-fit polyfill
var objectFitImages = require('object-fit-images');
objectFitImages();

// Base
// require('/util/_fetch-svg');

require('../js/modules/_menu');
require('../js/modules/_header');
require('../js/modules/_accordion');
require('../js/modules/_testimonial_slider');
